<template lang="pug">
  .p-game-container
    .p-game-layout
    .pgf-container(v-if="!brand.slot_games_only")
      PGFilters

    .frame-container
      .navigations
        .navigations__money(@click="changeDemoModeHandler" v-b-tooltip.hover :title="getTooltipText('money')")
          IUG_MoneyGameSVG(:isClickedCross="isDemoModeClicked")

        .navigations__fscreen(@click="openNewTabHandler" v-b-tooltip.hover :title="getTooltipText('screen')")
          IUG_FullScreenSVG(:isClickedCross="isNewTabClicked")

        //.navigations__unwrap(@click="fullScreenHandler")
        //  IUG_UnwrapSVG(:isClickedCross="isFullScrClicked")

        .navigations__fav(@click="favoriteHandler" v-b-tooltip.hover :title="getTooltipText('favorites')")
          IUG_Favorites(:isClickedCross="isFavoritesClicked || isFavorite")

        .navigations__reload(@click="reloadIFrameHandler" v-b-tooltip.hover :title="getTooltipText('reload')")
          IUG_Reload(:isClickedCross="isReloadClicked")

        .navigations__cross(@click="closeIFrame" v-b-tooltip.hover :title="getTooltipText('home')")
          IUG_Cross(:isClickedCross="isCloseClicked")

      iframe.frame(ref="frameGames" seamless="seamless" allowfullscreen="allowfullscreen")

    .desc-container
      .btn-wrapper(v-show="isDemo" @click="changeDemoModeHandler")
        Button(:title="$t('games.realButton')" buttonShape="gradientOff"
              buttonStyle="personalGame" :isBtnDisabled="isBtnDisabled")

      .content-desc {{ gameDescription }}

    .similar-container
      .similar-container__title {{ $t('games.similarGamesLabel') }}
      .left-wrapper(@click="rotatorHandler('left')")
      .right-wrapper(@click="rotatorHandler('rigth')")

      .slider-wrapper
        .gl-wrap(ref='pgames_wrap' :style="sliderPositionStr")
          .gl-card(v-for='(icon,index) in sliderList' :key='index' :key-id='icon.id' @click.stop="selectGame(icon.id, false, index)" :class="{'gl-card_selected': (index === gameSelectIndex)}"  )
            .gl-btn-demo( @click.stop="selectGame(icon.id, true, index)") {{ $t('games.demoButton') }}
            .gl-btn-new
            .gl-btn-favorites( :class="{'gl-btn_selected': icon.is_favorite}" @click.stop="setFavorite(icon.id, !icon.is_favorite)")
            .gl-card_image(@mouseover="on_over" @mouseout="on_out")
              object.icon(type="image/svg+xml" :data-game_id="'game_'+icon.id" :ref="'game_'+icon.id" :data="url_icon(icon.image_file)" @load="on_svg_load" )
            .gl-card__title {{ getTitle(icon) }}  
</template>

<script>

const PGFilters = () => import('@/components/games/personal/PGFiltersSW');
const Button = () => import('@/components/serviceComponents/ButtonSW');
const IUG_MoneyGameSVG = () => import('@/components/serviceComponents/svg/personalGame/IUG_MoneyGame.vue');
const IUG_FullScreenSVG = () => import('@/components/serviceComponents/svg/personalGame/IUG_FullScreen.vue');
const IUG_UnwrapSVG = () => import('@/components/serviceComponents/svg/personalGame/IUG_Unwrap.vue');
const IUG_Favorites = () => import('@/components/serviceComponents/svg/personalGame/IUG_Favorites.vue');
const IUG_Reload = () => import('@/components/serviceComponents/svg/personalGame/IUG_Reload.vue');
const IUG_Cross = () => import('@/components/serviceComponents/svg/personalGame/IUG_Cross.vue');

import PersonalGame from './PersonalGame';

export default {
    extends: PersonalGame,
    components: {
      Button,
      IUG_Cross,
      IUG_Favorites,
      IUG_FullScreenSVG,
      IUG_MoneyGameSVG,
      IUG_Reload,
      IUG_UnwrapSVG,
      PGFilters,
    }
};
</script>

<style lang="scss">
@import '@/assets/styles/games/personal/personal-game_sw.scss';
</style>