<template lang="pug">
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'PersonalGame',
    props: {
      gameId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      gameLink: 'https://wbgdev.ngmcasino.com/wbg_server/index.php?',
      sliderList: [],
      isDemo: null,
      isFavorite: false,
      isDemoModeClicked: false,
      isNewTabClicked: false,
      isFullScrClicked: false,
      isReloadClicked: false,
      isCloseClicked: false,
      isFavoritesClicked: false,
      sliderPosition: -1128,
      cardWidth: 222,
      sliderIndex: 0,
      sliderPositionStr: '',
      isBtnDisabled: false,
      //Выбранная обложка
      gameSelectIndex: -1,
      //Указатель на корневой элемент СВГ обложки
      svg: null,
      //Флаг наведения
      hover: false,
      toggleTooltip: false,
      tooltipTitle: null,
    }),
    computed: {
      ...mapGetters({
        isAuthUser: 'auth/isAuthUser',
        userLanguageCode: 'user/getLanguageCode',
        token: 'games/getLaunchToken',
        list: 'games/getList',
        isDemoRunGame: 'games/isDemoRunGame',
        gameDescription: 'games/getGameDescription',
        brand: 'main/getBrand',
      }),
      isMobile() {
        return window.innerWidth < 1280;
      },
      getTitleForMoney() {
        return this.isDemo ? this.$t('games.realButton') : this.$t('games.demoButton');
      },
      getTitleForFScreen() {
        return this.$t('games.fullScreenLabel');
      },
      getTitleForFavorite() {
        return this.isFavorite ? this.$t('games.favoritesRemoveLabel') : this.$t('games.favoritesLabel');
      },
      getTitleForReload() {
        return this.$t('games.reloadLabel');
      },
      getTitleForHome() {
        return this.$t('footer.footerHomeLabel');
      },
    },
    methods: {
      ...mapActions({
        fetchGameList: 'games/fetchGameList',
        doChangeFavorites: 'games/doChangeFavorites',
        fetchLaunchToken: 'games/fetchLaunchToken',
        fetchGameDesc: 'games/fetchGameDesc',
        fetchGameInfo: 'games/fetchGameInfo',
        fetchGameFavorite: 'games/fetchGameFavorite',
      }),
      ...mapMutations({
        SET_DEMO_RUN_GAME: 'games/SET_DEMO_RUN_GAME',
      }),
      url_icon(image_file) {
        let out = 'game-icons/no_cover.svg';
        if (image_file !== null)
          try {
            out = 'game-icons/' + image_file;
          } catch (e) {
            console.log('Cover not find: ', image_file);
          }
        return out;
      },
      /**
       * @param direction
       * метод для осуществления ротации влево и вправо игр из
       * блока Similar games
       */
      rotatorHandler(direction) {
        this.sliderPosition += (direction == 'left') ? this.cardWidth : -this.cardWidth;

        if (direction == 'left') {
          this.sliderIndex = Math.ceil(this.sliderPosition / this.cardWidth);

          if (this.sliderIndex === 0) {
            this.changeSliderPositionAnimOff();
            this.sliderPosition = -2018;
          }
          else {
            this.changeSliderPosition();
          }
        }
        else {
          this.sliderIndex = Math.floor(Math.abs(this.sliderPosition / this.cardWidth));

          if (this.sliderIndex === 12) {
            this.changeSliderPositionAnimOff();
            this.sliderPosition = -464;
          }
          else {
            this.changeSliderPosition();
          }
        }
      },
      /**
       * метод для переключения режимов "демо-реальные деньги"
       */
      async changeDemoModeHandler() {
        this.isDemoModeClicked = true;
        this.isDemo = !this.isDemo;
        let isLoad = await this.loadGame(this.isDemo);
        if (!isLoad) {
          this.isDemo = !this.isDemo;
        }
        setTimeout(() => {
          this.isDemoModeClicked = false;
        }, 500);
      },
      /**
       * метод открытия айфрем в новой вкладке
       */
      async openNewTabHandler() {
        this.isNewTabClicked = true;
        const info = await this.fetchGameInfo(this.gameId);
        if (info.is_enabled) {
          window.open(this.buildGameUrl(this.isDemo, true));
          setTimeout(() => {
            this.isNewTabClicked = false;
          }, 500);
        }
      },
      /**
       * метод открытия айфрем во весь экран
       */
      fullScreenHandler() {
        this.isFullScrClicked = true;
        document.querySelector('.frame-container').style = 'z-index:15;';
        document.querySelector('.frame').style = 'position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;';
        setTimeout(() => {
          this.isFullScrClicked = false;
        }, 500);
      },
      /**
       * метод перезагрузки айфрем
       */
      reloadIFrameHandler() {
        this.isReloadClicked = true;
        try {
          document.querySelector('.frame').src = document.querySelector('.frame').src;
        } finally {
          setTimeout(() => {
            this.isReloadClicked = false;
          }, 500);
        }
      },
      /**
       * метод закрытия айфрем
       */
      closeIFrame() {
        this.isCloseClicked = true;
        this.$router.push('/').catch({});
        window.scrollTo(0, 0);
        this.isCloseClicked = false;
      },
      /**
       * метод добавления в закладки
       */
      async favoriteHandler() {
        this.isFavoritesClicked = true;
        try {
          await this.doChangeFavorites({id: this.gameId, value: !this.isFavorite});
          this.isFavorite = !this.isFavorite;
        } finally {
          setTimeout(() => {
            this.isFavoritesClicked = false;
          }, 500);
        }
      },
      /**
       * метод запуска игры
       * @returns {string}
       */
      buildGameUrl(isDemo, isHomeButton = false) {
        const searchParams = new URLSearchParams({
              r: 'x-slots/start-game-no-cookie',
              demo: isDemo ? 1 : 0,
              homeless: isHomeButton ? 0 : 1
            }
        );
        searchParams.append('game_id', this.gameId);
        searchParams.append('lang', this.userLanguageCode);
        searchParams.append('back_url', window.location.href);
        searchParams.append('partner_id', process.env.VUE_APP_PARENT_ID);

        if (!isDemo) {
          if (this.gameId != null && this.gameId !== '') {
            searchParams.append('token', this.token);
          }
        }

        return `${this.gameLink}${searchParams.toString()}`;
      },
      /**
       * переключение позиции текущего слайда
       */
      changeSliderPosition() {
        this.sliderPositionStr = 'transform: translateX(' + this.sliderPosition + 'px);transition: transform 1.0s ease';
      },
      /**
       * переключение позиции текущего слайда
       */
      changeSliderPositionAnimOff() {
        this.changeSliderPosition();

        setTimeout(() => {
          this.sliderPositionStr = 'transform: translateX(' + this.sliderPosition + 'px);';
        }, 1000);
      },
      async sliderGameHandler(gameId) {
        await this.$router.push(`/game?id=${gameId}`);
        window.scrollTo(0,0);
        this.loadGame(this.isDemo);
      },
      async loadGame(isDemo) {
        let isLoadToken = true;
        if (!isDemo) {
          if (this.$metrika) {
            this.$metrika.reachGoal('gameRun');
          }

          isLoadToken = await this.fetchLaunchToken(this.gameId);
        }
        if (isLoadToken) {
          const info = await this.fetchGameInfo(this.gameId);
          if (!info.is_enabled) {
            return false;
          }
          this.$refs['frameGames'].src='about:blank';
          this.$refs['frameGames'].src = this.buildGameUrl(isDemo);

          await this.fetchGameDesc(this.gameId);
          await this.fetchGameList(this.gameId);

          /**
           * модифицируем массив с играми для ротатора
           */
          const threeEndElement = this.list.slice(this.list.length - 5, this.list.length);
          const threeFirstElement = this.list.slice(0, 3);
          this.sliderList = [...threeEndElement, ...this.list, ...threeFirstElement];
          return true;
        }
        return false;
      },
      getTitle(card) {
          return (typeof card['title_'+this.userLanguageCode] === 'string'?card['title_'+this.userLanguageCode]:'');
      },
      //Обработчик выбора обложки
      selectGame(gameId = 0, isDemo = false, index = -1) {
        if (!this.isMobile) {
          if (isDemo) {
            this.SET_DEMO_RUN_GAME(true);
          }
          //this.doOpenGamePage(gameId);
          this.sliderGameHandler(gameId);
        } else {
          if (this.gameSelectIndex !== index) {
            this.gameSelectIndex = index;
          } else {
            if (!isDemo && this.$metrika) {
              this.$metrika.reachGoal('gameRun');
            }
            this.doOpenGameUrl(gameId, isDemo);
          }
        }
      },
      async doOpenGamePage(gameId) {
        if (!this.isMobile) {
          this.SET_OPEN_PAGE('personalGame');
          await this.$router.push(`/game?id=${gameId}`);
          window.scrollTo(0, 0);
        }
      },
      async doOpenGameUrl(gameId, isDemo = false) {
        if (isDemo === true || !this.isAuthUser)
          window.location.href = this.url_run(gameId, true);
        else {
          let isLoadToken = await this.fetchLaunchToken(gameId);
          if (isLoadToken) {
            if (this.token.length > 0) {
              const url_game_run = this.url_run(gameId, false);
              this.SET_LAUNCH_TOKEN('');
              window.location.href = url_game_run;

              if (this.$metrika) {
                this.$metrika.reachGoal('gameRun');
              }
            }
            this.SET_LAUNCH_TOKEN('');
          }
        }
      },
      async setFavorite(game_id, is_favorite) {
        await this.doChangeFavorites(
            {
              id: game_id,
              value: is_favorite
            }
        );
      },
      async on_over(event) {
        this.hover = true;
        this.icon_stop();
        this.svg = event.target.firstChild.contentDocument.documentElement;
        this.icon_play();
      },
      async on_out() {
        this.hover = false;
      },
      async on_svg_load(event) {
          this.svg = event.target.contentDocument.documentElement;
          this.icon_stop();
      },
      icon_play() {
        if (
            this.svg &&
            this.svg !== null &&
            this.svg.unpauseAnimations &&
            this.svg.animationsPaused &&
            this.svg.animationsPaused() === true
        )
          this.svg.unpauseAnimations();
      },
      icon_stop() {
        if (
            this.svg &&
            this.svg !== null &&
            this.svg.pauseAnimations &&
            this.svg.animationsPaused() === false
        )
          this.svg.pauseAnimations();
          this.svg = null;
      },

      getTooltipText(divName) {
        switch (divName) {
          case 'money': {
            return this.getTitleForMoney;
          }
          case 'screen': {
            return this.tooltipTitle = this.getTitleForFScreen;
          }
          case 'favorites': {
            return this.tooltipTitle = this.getTitleForFavorite;
          }
          case 'reload': {
            return this.tooltipTitle = this.getTitleForReload;
          }
          case 'home': {
            return this.tooltipTitle = this.getTitleForHome;
          }
          default: {
            return null;
          }
        }
      },
    },
  watch:{
    isAuthUser(newAuth, oldAuth) {
      if (newAuth !== oldAuth && newAuth !== null) {
        let isDemo = !newAuth;
        if (this.isDemo !== isDemo) {
          this.isDemo = isDemo;
          this.loadGame(this.isDemo);
          this.SET_DEMO_RUN_GAME(this.isDemo);
        }
      }
    }
  },
  async beforeMount() {
    this.changeSliderPosition();
  },
  async mounted() {
      if (this.isAuthUser !== null) {
        this.isDemo = !this.isAuthUser || this.isDemoRunGame;
        this.loadGame(this.isDemo);
        this.SET_DEMO_RUN_GAME(this.isDemo);
        this.isFavorite = await this.fetchGameFavorite(this.gameId);
      }
    }
};
</script>

<style lang="scss">
</style>